<template>
    <div class="bodylist">
        <div class="tname">
        <p><img style="margin-left:20px" src="../../assets/image/logo.png" /> <span style="font-size:36px;margin-left:10px;font-weight: 800;">中联燃气</span> <img style="margin-left:50%" src="../../assets/image/logo_r.png"></p>
        </div>
        <div class="bgcontent">
            
           <div class="showcontent">
              <div><img src="../../assets/indexpc/succeed.png"></div>
              <div class="title1">报装申请提交成功</div>
              <div class="title2">工作人员稍后会与您联系，请保持电话通畅</div>
              <div><el-button type="primary" @click="goPath('/progress')">查看进度</el-button></div>
           </div>
        </div>
       
    </div>
</template>
<script>
 export default {
    name: 'home',
     methods: {
      goPath(path){
          this.$router.push({ path: path, query: {} })
      }
    }
 }
</script>

<style  scoped lang="scss">
.bodylist{
    margin: 0px 15%;
}
.tname {
  font-size: 0.5rem;
  text-align: center;
   margin-top:30px;
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 0.7rem;
    width: 100%;
    margin: 0 auto;
    
  }
}
.bgcontent{background-image: url('../../assets/indexpc/indexbg.png');background-size: 100% 100%;width: 100%;height: 100%;display: block;top:30px;
    position: relative;}

.showcontent{
    padding: 91px 0px 0px 80px;
    text-align: center;
    }
.title1{
    font-size: 22px;
font-weight: bold;
color: #181818;

}
.title2{
    font-size: 14px;
font-weight: 500;
color: #989797;
margin-top: 13px;
margin-bottom: 40px;
}
</style>